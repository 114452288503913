import React from 'react';
import { AiOutlineDoubleRight } from "react-icons/ai";
import { BiSolidPhoneCall } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import { MdVolunteerActivism } from 'react-icons/md';

function WhoweHome() {
    let navigate = useNavigate()
    let volunteer = () => {
        navigate("/volunteerpage")
    }
    return (
        <>
            <div className='whowebackimage'>
                <div className='container py-5'>
                    <div className='row m-0 p-0 g-4 py-2'>
                        <div className='col-12 col-lg-6' data-aos="zoom-in"
                            data-aos-delay="50"
                            data-aos-duration="1500">
                            <div className='h-100'>
                                <div className='abouththought fw-bold headcolor'> <span className='fs-3 '><MdVolunteerActivism /></span> Who We Are</div>
                                <div className='abouthead fs-1 fw-bold '>How To Become A <span className='headcolor'>Volunteer?</span></div>
                                <div className='pera py-1' style={{textAlign:'justify'}}> <span className='abouththought fs-5 fw-bold'><AiOutlineDoubleRight className='headcolor' /></span> Volunteerism is the voice of the people put into action.  These actions shape and mold the present into a future of which we can all be proud.</div>
                                <div className='pera py-1' style={{textAlign:'justify'}}><span className='abouththought fs-5 fw-bold'><AiOutlineDoubleRight className='headcolor' /></span> Everybody can be great, because anybody can serve. You don’t have to have a college degree to serve; you only need a heart full of grace and a soul generated by love. The quality of a volunteer's life will be determined by the quality of their contributions. When they work to improve the lives of others, their own lives improve automatically.</div>
                                <div className='pera py-1'> <span className='abouththought fs-5 fw-bold'><AiOutlineDoubleRight className='headcolor' /></span> <b>Let's be make a part of volunteer.</b></div>
                                <div className='d-block d-md-block d-lg-flex d-md-flex d-sm-flex  justify-content-content align-itmes-center m-4 ms-0'>
                                    <div className='volunteer p-2 m-2 ms-0  becometext fs-5 align-items-center d-flex' onClick={volunteer}>Become Volunteer</div>
                                    <div className='border border-1 border-secondary border-start ms-4 d-none d-md-none d-lg-block m-1'></div>
                                    <div className='d-flex whowecallus ps-0 ps-lg-3 m-lg-2 m-3 ms-0'>
                                        <div className='fs-3 whowecall p-2 text-center d-flex align-items-center justify-content-center align-content-center'><BiSolidPhoneCall /></div>
                                        <div className='ps-3'>
                                            <div className='fw-bold'>call us</div>
                                            <div className='fw-bold numbercall'>(+91) 90676 65653</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-6' data-aos="zoom-in"
                            data-aos-delay="50"
                            data-aos-duration="1800" >
                            <div className='h-100 volunimage'>
                                <img src={require("../assets/image/HomePage/BecomeVolunteer.jpg")} alt="" className='img-fluid object-fit-cover h-100' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhoweHome